import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Divider from "../Branch/settlement/components/Divider";

const Timeline = ({ value }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <h5>Status</h5>
      <Divider width={30} />
      <div
        style={{
          backgroundColor: "#90f597",
          width: "50px",
          height: "50px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "20px",
        }}
      >
        <FontAwesomeIcon icon={faCheck} size="2x" color="green" />
        <div
          style={{
            position: "absolute",
            left: "40%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Placed
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          left: "14%",
          border: value > 1 ? `5px dashed #90f597` : `5px dashed lightgrey`,
          height: "50px",
          color: "red",
        }}
      ></div>
      <div
        style={{
          backgroundColor: value > 1 ? "#90f597" : "lightgrey",
          width: "50px",
          height: "50px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          color={value > 1 ? "green" : "grey"}
        />
        <div
          style={{
            position: "absolute",
            left: "40%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Accepted
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "14%",
          border: value > 2 ? `5px dashed #90f597` : `5px dashed lightgrey`,
          // borderRadius: "5px",
          height: "50px",
          color: "red",
        }}
      ></div>
      <div
        style={{
          backgroundColor: value > 2 ? "#90f597" : "lightgrey",
          width: "50px",
          height: "50px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          color={value > 2 ? "green" : "grey"}
        />
        <div
          style={{
            position: "absolute",
            left: "40%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Preparing
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "14%",
          border: value > 3 ? `5px dashed #90f597` : `5px dashed lightgrey`,
          // borderRadius: "5px",
          height: "50px",
          color: "red",
        }}
      ></div>
      <div
        style={{
          backgroundColor: value > 3 ? "#90f597" : "lightgrey",
          width: "50px",
          height: "50px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          color={value > 3 ? "green" : "grey"}
        />
        <div
          style={{
            position: "absolute",
            left: "40%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          On Delivery
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "14%",
          border: value > 4 ? `5px dashed #90f597` : `5px dashed lightgrey`,
          // borderRadius: "5px",
          height: "50px",
          color: "red",
        }}
      ></div>
      <div
        style={{
          backgroundColor: value > 4 ? "#90f597" : "lightgrey",
          width: "50px",
          height: "50px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="2x"
          color={value > 4 ? "green" : "grey"}
        />
        <div
          style={{
            position: "absolute",
            left: "40%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Delivered
        </div>
      </div>
    </div>
  );
};

export default Timeline;
