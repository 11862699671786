// import { number } from "prop-types";
import {
  LOADED,
  LOADING,
  GET_CATEGORY,
  APP_STATUS,
  CHANGE_DHELIMIT,
  CHANGE_OHELIMIT,
  UPDATE_LIMIT,
} from "./constants";

const initialState = {
  loading: false,
  categories: [],
  appStatus: false,
  DHELimit: 0,
  OHELimit: 0,
  minOrderAmtLimit: 0,
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        categories: payload,
      };

    case APP_STATUS:
      return {
        ...state,
        appStatus: payload.appStatus,
        DHELimit: payload.DHELimit,
        OHELimit: payload.OHELimit,
        minOrderAmtLimit: payload.minOrderAmtLimit,
        minDeliveryCharge: payload.minDeliveryCharge,
        minDeliveryDistance: payload.minDeliveryDistance,
      };
    case CHANGE_OHELIMIT:
      return {
        ...state,
        OHELimit: payload,
      };
    case UPDATE_LIMIT:
      return {
        ...state,
        ...payload,
      };
    case CHANGE_DHELIMIT:
      return {
        ...state,
        DHELimit: payload,
      };
    default:
      return state;
  }
}
